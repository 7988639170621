import * as React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import renderElements from "../components/elements";
import {ParallaxProvider} from "react-scroll-parallax";

export const query = graphql`
  query($id: String!) {
    wpPage(
        id: { eq: $id },
        ) {
        id
      uri
      title
      hideMenu
      renderContent
    }
  }
`

const SecondPage = props => {

    let path = props.pageContext.uri;

    if(props.data.wpPage === null)  return null;

    return (
        <ParallaxProvider>
            <Layout
                contentObjects={JSON.parse(props.data.wpPage.renderContent)}
                path={path}
                hideMenu={props.data.wpPage.hideMenu}
                title={props.data.wpPage.title}
            >   
                {renderElements(props.data.wpPage.renderContent)}
            </Layout>
        </ParallaxProvider>
    )
}

export default SecondPage